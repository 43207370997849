import Api from '@/services/Api'

export default {

    // Payload is created object that is passed to the endpoint to push values upstream to DB
    getPeopleByAssociation(payload) { return Api().get(`getPeopleByAssociation/${payload.association_id}`) },
    getArchivedPeopleByAssociation(payload) { return Api().get(`getArchivedPeopleByAssociation/${payload.association_id}`) },
    getArchivedPeople() { return Api().get('getArchivedPeople') },
    getPersonByID(payload) { return Api().get(`getPersonByID/${payload.person_id}`) },
    addNewPerson(payload) { return Api().post('peopleAdd', payload) },
    sendPersonEdit(payload) { return Api().post('sendPersonEdit', payload) },
    deletePerson(payload) { return Api().post('deletePerson', payload) },
    deleteAllLateFees(payload) { return Api().post('deleteAllLateFees', payload) },
    sendAddPayment(payload) { return Api().post('addPayment', payload) },
    addReoccurring(payload) { return Api().put('addReoccurring', payload) },
    findPersonByName(payload) { return Api().get('findPersonByName', payload) },
    searchPersonByName(payload) { return Api().get('searchPersonByName', payload) },
    autoArchiveAll(payload) { return Api().post('autoArchiveAll', payload) },
    autoUnarchiveAll(payload) { return Api().post('autoUnarchiveAll', payload) },
}